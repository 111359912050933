.invalid-feedback{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    margin: 20px 0 0 0;
    color: rgb(211, 47, 47);
    padding: 11px 10px 10px 10px;
    font-size: 12px;
}
/*.gradient-button {
    background: linear-gradient(90deg, #05B5CD 0%, #02D2D2 100%);
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 5s ease;
    -webkit-transition: background 5s ease;
}

.gradient-button:hover {
    background: linear-gradient(90deg, #059EB2 0%, #01B7B7 100%);
}

.gradient-button:disabled {
    background: linear-gradient(90deg, #05B5CD 0%, #02D2D2 100%);
    filter: grayscale(50%) opacity(0.8);
    cursor: not-allowed;
}*/

.buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.gradient-button {
    background-color: #02D2D2; /* Базовый цвет кнопки */
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    -webkit-transition: box-shadow 0.3s ease, background-color 0.3s ease;

    /* Создание эффекта градиента с помощью box-shadow */
    position: relative;
    overflow: hidden;

    /* Убираем внутренние отступы для корректного отображения границы */
    padding: 10px 20px;

    /* Градиентный эффект слева направо */
    background-color: #02D2D2;
    box-shadow: inset -60px 0 20px -10px #05B5CD, inset 60px 0 20px -10px #01B7B7;
}

.gradient-button:hover {
    background-color: #01B7B7; /* Базовый цвет при наведении */
    box-shadow: inset -60px 0 20px -10px #059EB2, inset 60px 0 20px -10px #05B5CD;
}
