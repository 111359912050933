.chat-container {
    display: flex;
    height: calc(100vh - 122px);
    max-width: 900px;
    margin: 0 auto 0 auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.chat-sidebar {
    width: 250px;
    padding: 10px;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 10px 0 0 10px;
}

.chat-main {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.chat-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-item:hover {
    background-color: #e9f5ff;
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.welcome-message {
    text-align: center;
    color: #666;
}

.chat-name {
    margin: 0 0 0 15px;
}

.chat-item.active {
    background-color: #f0f0f0;  /* Цвет фона для активного чата */
    border-left: 4px solid #007bff;  /* Линия слева для выделения */
}

.chat-item.active .chat-name {
    font-weight: bold;  /* Можно сделать текст активного чата жирным */
}

.message {
    display: flex;
    margin-bottom: 15px;
}

.message.partner {
    justify-content: flex-start;
}

.message.me {
    justify-content: flex-end;
}

.message-text {
    max-width: 60%;
    padding: 10px;
    border-radius: 20px;
    background-color: #f0f0f0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.message.me .message-text {
    background-color: #007bff;
    color: white;
}

.message-partner .message-text {
    background-color: #f1f1f1;
    color: #333;
}

.message-date {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .chat-container {
        flex-direction: column;
    }

    .chat-sidebar {
        width: 100%;
        padding: 10px;
    }

    .chat-main {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
    }

    .chat-item {
        flex-direction: column;
        align-items: flex-start;
    }
}
