.quiz.fill:hover,
.quiz.fill:focus {
    box-shadow: inset 0 0 0 2em var(--hover);
}

.quiz.pulse:hover,
.quiz.pulse:focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 1em transparent;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover);
    }
}
.quiz.close:hover,
.quiz.close:focus {
    box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
}

.quiz.raise:hover,
.quiz.raise:focus {
    box-shadow: 0 0.3em 0.3em -0.2em var(--hover);
    transform: translateY(-0.15em);
}

.quiz.up:hover,
.quiz.up:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

.quiz.slide:hover,
.quiz.slide:focus {
    box-shadow: inset 6.5em 0 0 0 var(--hover);
}

.quiz.offset {
    box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);
}
.quiz.offset:hover, .quiz.offset:focus {
    box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
}

.quiz {
    --color: #3c3c74;
    --hover: #537df6;
}

button.quiz {
    color: var(--color);
    transition: 0.25s;
}
button.quiz:hover, button.quiz:focus {
    border-color: var(--hover);
    color: #fff;
}

button.quiz {
    background-image: linear-gradient(to top, #3c3c74 0%, #537df6 50%, #3c3c74 200%);
    border: 0;
    line-height: 1;
    height: 50px;
    width: 60%;
    color: #fff;
    /* backgroundColor:'#f99df0', */
    /* backgroundImage: 'linear-gradient(155deg, #8768c8, #f99df0)', */
    /* backgroundImage: 'linear-gradient(0, #537df6, #3c3c74)', */
    min-width: 233px;
    max-width: 238px;
    cursor: pointer;
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 6px;
    margin: 20px auto 20px auto;
    padding: 10xp 5px;
    background-size: 200% auto;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: 0.5s;
    background-color: #537df6;
    border-color: #537df6;
}