.social-kndev{
    margin: 38px auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}
.social-kndev.r1{max-width: 240px; margin: 15px auto 0 auto}
.social-kndev.r2{max-width: 190px; margin: 15px auto 10px auto}
.icon-kndev{
    display: block;
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
}
.icon-kndev.icon-opacity-kndev{
    transition: opacity 300ms;
    opacity: 0.33
}
.icon-kndev:hover{opacity: 1}