.table__link {
    color: #1976d2;
}
.table__link>img{
    fill: #1976d2;
}
.table__link:hover>img {
    fill: #1565c0;
}
.table__link:hover {
    color: #1565c0;
    cursor: pointer;
}