.agreementtext,
.modal {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.popup-overlay{
    z-index: 1400 !important;
}
.popup-content{
    width: calc(100% - 80px) !important;
    max-width: 800px;
    height: calc(100% - 80px);
    max-height: 800px;
    border-radius: 15px;
    overflow: visible;
}
.modal {
    font-size: 18px;
    margin: 20px 0 0 0;
    padding: 0 10px 0 10px;
    overflow-y: auto;
    height: calc(100% - 25px);
}

.modal::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #8e24aa;
}

.modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modal > .content {
    width: 100%;
    padding: 10px 5px;
}
.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.modal > .close {
    z-index: 1401;
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}