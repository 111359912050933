.MuiPickersDay-root.Mui-selected,
.MuiPickersDay-root.Mui-selected:hover,
.MuiPickersYear-root>.MuiPickersYear-yearButton.Mui-selected:hover,
.MuiPickersYear-root>.MuiPickersYear-yearButton.Mui-selected:focus,
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersArrowSwitcher-root{
    background-color: #740BB6;
}
.MuiPickersArrowSwitcher-root{
    border-radius: 20px;
}
.MuiPickersDay-root:hover{
    background-color: #02D2D2;
}
.MuiPickersCalendarHeader-switchViewButton > .MuiPickersCalendarHeader-switchViewIcon:hover,
.MuiPickersArrowSwitcher-root > .MuiPickersArrowSwitcher-button:hover{
    background-color: #02D2D2;
}
.MuiPickersCalendarHeader-switchViewButton > .MuiPickersCalendarHeader-switchViewIcon,
.MuiPickersArrowSwitcher-root > .MuiPickersArrowSwitcher-button > .MuiSvgIcon-root{
    fill: #FFFFFF;
}
.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton{
    padding: 0;
}
.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton >.MuiPickersCalendarHeader-switchViewIcon{
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 20px;
}
.MuiPickersCalendarHeader-labelContainer{
    color: #FFFFFF;
    padding: 0 0 0 15px;
    border-radius: 30px;
}
.MuiPickersArrowSwitcher-root,
.MuiPickersCalendarHeader-root > .MuiPickersCalendarHeader-labelContainer{
    position: relative;
    overflow: unset;
}
.MuiPickersArrowSwitcher-root:before,
.MuiPickersCalendarHeader-root > .MuiPickersCalendarHeader-labelContainer:before{
    background-color: #05B5CD;
    border: 1px solid #02D2D2;
    font-weight: 600;
    position: absolute;
    bottom: -1.6em;
    font-size: 0.6em;
    border-radius: 10px;
    padding: 3px 5px;
    z-index: 1;
    color: #FFFFFF;
    letter-spacing: 0.15em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.MuiPickersCalendarHeader-root > .MuiPickersCalendarHeader-labelContainer:before{
    content: 'Выбрать год';
    right: 1.6em;
}
.MuiPickersArrowSwitcher-root:before{
    content: 'Месяц';
    right: 1.6em;
}
.MuiDateCalendar-root > .MuiPickersCalendarHeader-root{
    margin-bottom: 1.5em;
}