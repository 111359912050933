.person_compatible.gold{
    background: #DAA520;
    border: 1px solid #DAA520;
}
.person_compatible.mid{
    background: #1e7fbb;
    border: 1px solid #1e7fbb;
}
.person_compatible.low{
    background: #303030;
    border: 1px solid #303030;
}
.person_region,
.person_compatible{
    color: #ffffff;
    position: absolute;
    /*opacity: 0.65;*/
    padding: 8px 6px 8px 6px;
    height: 8px;
    line-height: 8px !important;
    text-align: center;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 12px
}
.person_compatible{
    top: 5px;
    left: 5px;
}
.person_region{
    top: 20px;
    left: 5px;
}